import './style.css';
import './node_modules/ol-layerswitcher/dist/ol-layerswitcher.css'

import { Map, Overlay, View } from 'ol';
import { OSM, TileDebug, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { FullScreen, defaults as defaultControls, MousePosition, ScaleLine } from 'ol/control';
import { DragRotate, defaults as defaultInteractions } from 'ol/interaction';
import LayerSwitcher from 'ol-layerswitcher';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { toStringHDMS } from 'ol/coordinate';
import { Attribution } from 'ol/control';
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
  Graticule
} from 'ol/layer';
import {
  xyzLayer,
  osmLayer,
  stadiaMapsLayer,
  googleLayer,
  thunderforestLayer,
  hereLayer,
  tomtomLayer,
  mapboxLayer,
  cartoLayer,
  cartoFastlyLayer,
  locationIqLayer,
  mapyLayer,
  esriLayer,
  tileWmsLayer,
  tileArcGisRestLayer,
  epsg28992WmtsLayer,
  jawgLayer,
  esriFeatureLayer,
  geoJSONLayer,
  wfsLayer,
  kmlLayer,
} from './layerTypes';

function newGroup(title, layers = [], params = {}) {
  return new GroupLayer({
    title: title,
    layers: layers,
    fold: 'open',
    ...params,
  })
}

function getVisibleLayersFromURL(str){
  while((str.length % 4) != 0) {
    str = str + '=';
  }
  str.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(atob(str));
}

function setLayerVisibility(visibleLayers = visibleLayers) {
  map.getAllLayers().forEach(element => {
    if(visibleLayers.includes(element.get('title'))) {
      if(element.get('type') == 'base') {
        map.getAllLayers().forEach(element2 => {
          if(element.get('type') == 'base') {
            element2.setVisible(false)
          }
        })
      }
      element.setVisible(true)
    }
  })
}

function featureLayerPopUp(e, layerTitle, featureList) {
  return map.forEachFeatureAtPixel(e.pixel, function(feature) {
      console.log(feature);
      let clickedCoordinate = e.coordinate;
      for (var item in featureList) {
        let popupFeature = document.createElement('span');
        popupFeature.innerHTML = feature.get(featureList[item]);
        let brLine = document.createElement('br');
        overlayContainerElement.appendChild(popupFeature);
        overlayContainerElement.appendChild(brLine);
      }
      overlayLayer.setPosition(clickedCoordinate);
    },
    {
      layerFilter: function(layerCandidate){
        return layerCandidate.get('title') === layerTitle
      }
    }
  )
}

// map defaults
var zoom = 12;
var center = fromLonLat([5.387201, 52.155172]); // Locatie Onze Lieve Vrouwetoren Amersfoort
var rotation = 0;
var visibleLayers = ['Duitsland'];

// some url constants
const kartoGiraffeUrl = 'https://tiles.kartogiraffe.de/tiles/tile.php?zoom={z}&x={x}&y={y}';
const lvnlUrl = 'https://geoservices.lvnl.nl/arcgis/rest/services/';
const vfrAirspacesUrl = 'VFRchart/Airspaces/MapServer';

// Vector Feature Popup Logic
const overlayContainerElement = document.querySelector('.overlay-container');
const overlayLayer = new Overlay({
  element: overlayContainerElement
})

/*
██████   █████  ███████ ███████     ███    ███  █████  ██████  ███████
██   ██ ██   ██ ██      ██          ████  ████ ██   ██ ██   ██ ██
██████  ███████ ███████ ███████     ██ ████ ██ ███████ ██████  ███████
██   ██ ██   ██      ██ ██          ██  ██  ██ ██   ██ ██           ██
██████  ██   ██ ███████ ███████     ██      ██ ██   ██ ██      ███████
*/

const baseMaps = newGroup('Base maps', [
  newGroup('OpenStreetMap', [
    new TileLayer({
      title: 'Standaard',
      source: new OSM(),
      type: 'base',
      visible: false,
    }),
    osmLayer('Humanitarian', 'https://{a-c}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { type: 'base' }),
    osmLayer('Duitsland', 'https://{a-c}.tile.openstreetmap.de/{z}/{x}/{y}.png', { type: 'base', /* visible: true */}),
    osmLayer("Frankrijk", "//{a-c}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png", { type: 'base' }),
    xyzLayer("Piano", "https://{a-c}.piano.tiles.quaidorsay.fr/en/{z}/{x}/{y}.png", { type: 'base' },
      { maxZoom: 16, attributions: "map data \u00a9 <a href='https://osm.org/copyright' target='_blank'>OpenStreetMap contributors</a> under ODbL - tiles \u00a9 <a href='https://github.com/tilery/pianoforte' target='_blank'>Min. Aff. \u00c9trang\u00e8res</a>",}),
    xyzLayer("Forte", "https://{a-c}.forte.tiles.quaidorsay.fr/en/{z}/{x}/{y}.png", { type: 'base' },
      { maxZoom: 16, attributions: "map data \u00a9 <a href='https://osm.org/copyright' target='_blank'>OpenStreetMap contributors</a> under ODbL - tiles \u00a9 <a href='https://github.com/tilery/pianoforte' target='_blank'>Min. Aff. \u00c9trang\u00e8res</a>",}),
  ]),

  newGroup('Google', [
    googleLayer('Road', 'm', { type: 'base' }, { maxZoom: 22 }),
    googleLayer('Road Altered', 'r', { type: 'base' }, { maxZoom: 22 }),
    googleLayer('Terrain', 'p', { type: 'base' }, { maxZoom: 22 }),
    googleLayer('Hybrid', 'y', { type: 'base' }, { maxZoom: 22 }),
    googleLayer('Satellite', 's', { type: 'base' }, { maxZoom: 22 }),
  ]),

  newGroup('PDOK',[
    epsg28992WmtsLayer('BRT-Achtergrondkaart', 'standaard', 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?', { maxZoom: 19.5 }),
    epsg28992WmtsLayer('BRT-Achtergrondkaart Pastel', 'pastel', 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?', { maxZoom: 19.5 }),
    epsg28992WmtsLayer('BRT-Achtergrondkaart Grijs', 'grijs', 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?', { maxZoom: 19.5 }),
    epsg28992WmtsLayer('BRT-Achtergrondkaart Water', 'water', 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?', { maxZoom: 19.5 }),
  
    epsg28992WmtsLayer('BGT-Achtergrondkaart (ZOOM IN!)', 'achtergrondvisualisatie', 'https://service.pdok.nl/lv/bgt/wmts/v1_0?service=WMTS&', { minZoom: 16.5, maxZoom: 19.5 }),
    epsg28992WmtsLayer('BGT-Standaard (ZOOM IN!)', 'standaardvisualisatie', 'https://service.pdok.nl/lv/bgt/wmts/v1_0?service=WMTS&', { minZoom: 16.5, maxZoom: 19.5 }),
    epsg28992WmtsLayer('BGT-Pastel (ZOOM IN!)', 'pastelvisualisatie', 'https://service.pdok.nl/lv/bgt/wmts/v1_0?service=WMTS&', { minZoom: 16.5, maxZoom: 19.5 }),
  
    epsg28992WmtsLayer('Luchtfotos (definitief actueel)', 'Actueel_ortho25', 'https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0?', { maxZoom: 19.5 }),
    epsg28992WmtsLayer('Luchtfotos (definitief actueel HR)', 'Actueel_orthoHR', 'https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0?', { maxZoom: 19.5 }),
  ]),

  newGroup('TopNL',[
    epsg28992WmtsLayer('10 - Zoom 14-16', 'top10nl', 'https://service.pdok.nl/brt/top10nl/wmts/v1_0?service=wmts?', { minZoom: 13.5, maxZoom: 16.5}),
    tileWmsLayer('25 - Zoom 13-16', 'https://service.pdok.nl/brt/topraster/wms/v1_0?service=wms', 'top25raster',  { minZoom: 13.5, maxZoom: 16.5, type: 'base'}),
    tileWmsLayer('50 - Zoom 12-15', 'https://service.pdok.nl/brt/topraster/wms/v1_0?service=wms', 'top50raster', { minZoom: 12.5, maxZoom: 15.5, type: 'base'}),
    tileWmsLayer('100 - Zoom 11-14', 'https://service.pdok.nl/brt/topraster/wms/v1_0?service=wms', 'top100raster', { minZoom: 11.5, maxZoom: 14.5, type: 'base'}),
    tileWmsLayer('250 - Zoom 10-13', 'https://service.pdok.nl/brt/topraster/wms/v1_0?service=wms', 'top250raster', { minZoom: 9.5, maxZoom: 12.5, type: 'base'}),
    tileWmsLayer('500 - Zoom 9-12', 'https://service.pdok.nl/brt/topraster/wms/v1_0?service=wms', 'top500raster', { minZoom: 8.5, maxZoom: 11.5, type: 'base'}),
    tileWmsLayer('1000 - Zoom 8-11', 'https://service.pdok.nl/brt/topraster/wms/v1_0?service=wms', 'top1000raster', { minZoom: 7.5, maxZoom: 10.5, type: 'base'}),
  ]),

  newGroup('ESRI', [
    esriLayer('Satellite', 'World_Imagery', { type: 'base' }),
    esriLayer('Oceans', 'Ocean/World_Ocean_Base', { type: 'base' }, { maxZoom: 16 }),
    // Deprecated
    esriLayer('National Geographic', 'NatGeo_World_Map', { type: 'base' }, { maxZoom: 16 }),
    esriLayer('USA Topo', 'USA_Topo_Maps', { type: 'base' }, { maxZoom: 15 }),
    esriLayer('World Physical Map', 'World_Physical_Map', { type: 'base' }, { maxZoom: 8 }),
    esriLayer('Standard', 'World_Street_Map', { type: 'base' }),
    esriLayer('World Terrain Base', 'World_Terrain_Base', { type: 'base' }, { maxZoom: 9 }),
    esriLayer('World Topo Map', 'World_Topo_Map', { type: 'base' }),
    esriLayer('Canvas Dark Gray Base','Canvas/World_Dark_Gray_Base', { type: 'base' }),
    esriLayer('Canvas Light Gray Base','Canvas/World_Light_Gray_Base', { type: 'base' }),
    esriLayer('Navigation Charts', 'Specialty/World_Navigation_Charts', { type: 'base' }, { maxZoom: 10 }),
  ]),

  newGroup('Stamen/StadiaMaps', [
    stadiaMapsLayer('Stamen Terrain', 'stamen_terrain'),
    stadiaMapsLayer('Stamen Terrain Background', 'stamen_terrain_background'),
    stadiaMapsLayer('Stamen Toner', 'stamen_toner'),
    stadiaMapsLayer('Stamen Toner Background', 'stamen_toner_background'),
    stadiaMapsLayer('Stamen Toner Lite', 'stamen_toner_lite'),
    stadiaMapsLayer('Stamen Watercolor', 'stamen_watercolor'),
    stadiaMapsLayer('Alidade Smooth', 'alidade_smooth'),
    stadiaMapsLayer('Alidade Smooth Dark', 'alidade_smooth_dark'),
    stadiaMapsLayer('OSM Bright', 'osm_bright'),
    xyzLayer(
      'Stadia Outdoors',
      'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}@2x.png',
      { type: 'base' }
    ),
  ]),

  newGroup('Thunderforest', [
    thunderforestLayer('Open Cycle Map', 'cycle', { type: 'base' }),
    thunderforestLayer('Transport', 'transport', { type: 'base' }),
    thunderforestLayer('Transport Dark', 'transport-dark', { type: 'base' }),
    thunderforestLayer('Landscape', 'landscape', { type: 'base' }),
    thunderforestLayer('Outdoors', 'outdoors', { type: 'base' }),
    thunderforestLayer('Spinal Map', 'spinal-map', { type: 'base' }),
    thunderforestLayer('Pioneer', 'pioneer', { type: 'base' }),
    thunderforestLayer('Mobile Atlas', 'mobile-atlas', { type: 'base' }),
    thunderforestLayer('Neighbourhood', 'neighbourhood', { type: 'base' }),
  ]),

  newGroup('Here Maps', [
    hereLayer('Normal', 'normal.day', 'base', { type: 'base' }),
    hereLayer('Transit', 'normal.day.transit', 'base', { type: 'base' }),
    hereLayer('Pedestrian', 'pedestrian.day', 'base', { type: 'base' }),
    hereLayer('Terrain', 'terrain.day', 'aerial', { type: 'base' }),
    hereLayer('Satellite', 'satellite.day', 'aerial', { type: 'base' }),
    hereLayer('Hybrid', 'hybrid.day', 'aerial', { type: 'base' }),
  ]),

  newGroup('TomTom', [
    tomtomLayer('Basic Main', 'basic/main', { type: 'base' }),
    tomtomLayer('Basic Night', 'basic/night', { type: 'base' }),
  ]),

  newGroup('Mapbox', [
    mapboxLayer('Satellite', 'satellite-v9', { type: 'base' }),
    mapboxLayer('Streets', 'streets-v11', { type: 'base' }),
    mapboxLayer('Outdoors', 'outdoors-v11', { type: 'base' }),
    mapboxLayer('Light', 'light-v10', { type: 'base' }),
    mapboxLayer('Dark', 'dark-v10', { type: 'base' }),
    mapboxLayer('Satellite and Streets', 'satellite-streets-v11', { type: 'base' }),
  ]),

  newGroup('Carto', [
    cartoLayer('Dark', 'dark_all', { type: 'base' }),
    cartoLayer('Dark - No labels', 'dark_nolabels', { type: 'base' }),
    cartoLayer('Spotify Dark', 'spotify_dark', { type: 'base' }),
    cartoLayer('Light', 'light_all', { type: 'base' }),
    cartoLayer('Light - No labels', 'light_nolabels', { type: 'base', visible: true }),
    cartoLayer('Voyager', 'rastertiles/voyager', { type: 'base' }),
    cartoLayer('Voyager - No labels', 'rastertiles/voyager_nolabels', { type: 'base' }),
    cartoFastlyLayer('Flat Blue', 'base-flatblue', { type: 'base' }),
    cartoFastlyLayer('Antique', 'base-antique', { type: 'base' }),
    cartoFastlyLayer('Midnight', 'base-midnight', { type: 'base' }),
    cartoFastlyLayer('Eco', 'base-eco', { type: 'base' }),
  ]),

  newGroup('LocationIQ', [
    locationIqLayer('LocationIQ Streets', 'streets', { type: 'base'}, { maxZoom: 20 }),
    locationIqLayer('LocationIQ Dark', 'dark', { type: 'base'}, { maxZoom: 20 }),
    locationIqLayer('LocationIQ Light', 'light', { type: 'base'}, { maxZoom: 20 }),
  ]),

  newGroup('Mapy', [
    mapyLayer('Basic map', 'basic', { type: 'base'}, { maxZoom: 20 }),
    mapyLayer('Tourist map', 'outdoor', { type: 'base'}, { maxZoom: 20 }),
    mapyLayer('Aerial map', 'aerial', { type: 'base' }, { maxZoom: 20 } ),
    mapyLayer('Winter map', 'winter', { type: 'base'}, { maxZoom: 20 }),
  ]),

  newGroup('Karto Giraffe', [
    xyzLayer('Base', kartoGiraffeUrl, { type: 'base' }),
    xyzLayer('Bicycle', kartoGiraffeUrl + '&type=bicycle', { type: 'base' }),
    xyzLayer('Hiking', kartoGiraffeUrl + '&type=hiking', { type: 'base' }),
    xyzLayer('Transport', kartoGiraffeUrl + '&type=transport', { type: 'base' }),
  ]),

  newGroup('Waze', [
    osmLayer('US', 'https://livemap-tiles{1-4}.waze.com/tiles/{z}/{x}/{y}.png', { type: 'base' }),
    osmLayer('World', 'https://worldtiles{1-4}.waze.com/tiles/{z}/{x}/{y}.png', { type: 'base' }),
  ]),

  newGroup('JAWG', [
    jawgLayer('Base', '', { type: 'base' }),
    jawgLayer('Sunny', 'sunny', { type: 'base' }),
    jawgLayer('Light', 'light', { type: 'base' }),
    jawgLayer('Dark', 'dark', { type: 'base' }),
    jawgLayer('Lagoon', 'jawg-lagoon', { type: 'base' }),
    jawgLayer('Terrain', 'jawg-terrain', { type: 'base' }),
  ]),

  newGroup('FlyToMap', [
    xyzLayer('Fly To Map', 'https://viewer.flytomap.com/ftm_tiles/{z}/{x}/{y}.png', { type: 'base'}),
    xyzLayer('Fly To Map No AA', 'https://viewer.flytomap.com/noaatiles/{z}/{x}/{y}.png', { type: 'base'}),
  ]),

  newGroup('Individuals', [
    xyzLayer('Freie Tonne Seekarte', 'https://www.freietonne.de/osm/{z}/{x}/{y}.png', { type: 'base' }),
    osmLayer('Incident Management Nederland', 'https://geoserver.lcm.nl/{z}/{x}/{y}.png', { type: 'base', extent: [313086.06785608083, 6418264.391049679, 939258.2035682462, 7200979.560689885]}),
    xyzLayer('Buienradar', 'https://tiles.buienradar.nl/tiles-eu-v2/{z}/{x}/{y}.png', { type: 'base'}, { maxZoom: 11}),
    xyzLayer('CyclOSM', 'https://{a-c}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', { type: 'base' }, { maxZoom: 20 }),
    xyzLayer('WhereGroup', 'https://osm-demo-{a-c}.wheregroup.com/tiles/1.0.0/osm/webmercator/{z}/{x}/{y}.png', { type: 'base' }, { maxZoom: 19 }),
    xyzLayer('Open Riverboat Map', 'https://{a-c}.tile.openstreetmap.fr/openriverboatmap/{z}/{x}/{y}.png', { type: 'base' }),
    xyzLayer('AllRailMap', 'https://map2.allrailmap.com/rail2/{z}/{x}/{y}.png', { type: 'base' }),
    xyzLayer('OpenTopoMap', 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png', { type: 'base' }),
    xyzLayer('(noSSL) Apple Maps Classic', 'http://gsp2.apple.com/tile?api=1&style=slideshow&layers=default&lang=en_GB&z={z}&x={x}&y={y}&v=9', { type: 'base' }),
    xyzLayer('NASA Blue Marble', 'https://wms.openstreetmap.fr/tms/1.0.0/nasa_blue_marble/{z}/{x}/{y}.jpeg', { type: 'base' }, { attributions: '<a href="https://earthobservatory.nasa.gov" target="_blank">NASA 2016</a>', maxZoom: 9 }),
    xyzLayer('NASA Black Marble', 'https://wms.openstreetmap.fr/tms/1.0.0/nasa_black_marble/{z}/{x}/{y}.jpeg', { type: 'base' }, { attributions: '<a href="https://earthobservatory.nasa.gov/features/NightLights" target="_blank">NASA 2012</a>', maxZoom: 9 }),
    xyzLayer('Maps For Free', 'https://maps-for-free.com/layer/relief/z{z}/row{y}/{z}_{x}-{y}.jpg', { type: 'base' }, { maxZoom: 11 }),
    xyzLayer('BlueMarble', 'https://s3.amazonaws.com/com.modestmaps.bluemarble/{z}-r{y}-c{x}.jpg', { type: 'base' }, { maxZoom: 9 }),
    xyzLayer('GIBS', 'https://gibs-{a-c}.earthdata.nasa.gov/wmts/epsg3857/best/MODIS_Terra_CorrectedReflectance_TrueColor/default/2020-06-15/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg', { type: 'base' }, {maxZoom: 9}),
    xyzLayer('Transport map', 'https://tile.memomaps.de/tilegen/{z}/{x}/{y}.png', { type: 'base'}),
  ])
]);


/*
 █████  ██████  ██████  ██ ████████ ██  ██████  ███    ██  █████  ██          ███    ███  █████  ██████  ███████
██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ████   ██ ██   ██ ██          ████  ████ ██   ██ ██   ██ ██
███████ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██ ██  ██ ███████ ██          ██ ████ ██ ███████ ██████  ███████
██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██  ██ ██ ██   ██ ██          ██  ██  ██ ██   ██ ██           ██
██   ██ ██████  ██████  ██    ██    ██  ██████  ██   ████ ██   ██ ███████     ██      ██ ██   ██ ██      ███████
*/

const overLayers = newGroup('Overlayers', [
  newGroup('Nederland Grenzen', [
    epsg28992WmtsLayer('BGT Omtrek (ZOOM IN!)', 'omtrekgerichtevisualisatie', 'https://service.pdok.nl/lv/bgt/wmts/v1_0?service=WMTS&', {type: '', minZoom: 16.5, maxZoom: 19.5 }),
    tileWmsLayer('Gemeentegrenzen', 'https://service.pdok.nl/kadaster/bestuurlijkegebieden/wms/v1_0?service=WMS&', 'Gemeentegebied'),
    tileWmsLayer('Landsgrens', 'https://service.pdok.nl/kadaster/bestuurlijkegebieden/wms/v1_0?service=WMS&', 'Landgebied'),
    tileWmsLayer('Provinciegrenzen', 'https://service.pdok.nl/kadaster/bestuurlijkegebieden/wms/v1_0?service=WMS&', 'Provinciegebied'),
    tileWmsLayer('Waterschappen', 'https://service.pdok.nl/hwh/administratieve-eenheden/waterschapsgrenzen/wms/v1_0?', 'AU.AdministrativeUnit', { }, { params: {STYLES: 'AU.AdministrativeUnit.Alternative'} }),
    tileWmsLayer('Veiligheidsregio (2024)', 'https://service.pdok.nl/cbs/gebiedsindelingen/2024/wms/v1_0?', 'veiligheidsregio_gegeneraliseerd,veiligheidsregio_labelpoint'),
    tileWmsLayer('GGD Regio (2024)', 'https://service.pdok.nl/cbs/gebiedsindelingen/2024/wms/v1_0?', 'ggdregio_gegeneraliseerd,ggdregio_labelpoint'),
    tileWmsLayer('Existing Land Use', 'https://service.pdok.nl/cbs/landuse/wms/v1_0?', 'LU.ExistingLandUse', { minZoom: 12.5 })
  ]),
  
  newGroup('Nederland Wegen', [
    tileArcGisRestLayer("RWS Metrering Wegen", 'https://geo.rijkswaterstaat.nl/arcgis/rest/services/GDR/nwb_metrering/MapServer', 'show:0,1,2,3' ),
    tileWmsLayer('Hectometerpalen ICM', 'https://geoserver.stichtingimn.nl/geoserver/ows?', 'bps:bps_palen', {}, { params: { 'TILED': true, 'VERSION': '1.1.1'} }),
    tileWmsLayer('NWB - Hectopunten', 'https://service.pdok.nl/rws/nwbwegen/wms/v1_0?', 'hectopunten'),
  ]),

  newGroup('Nederland Water', [
    tileArcGisRestLayer("RWS Strandpalen", 'https://geo.rijkswaterstaat.nl/arcgis/rest/services/GDR/topografie_kust/MapServer', 'show:4,5'),
    tileArcGisRestLayer("RWS Maritime Chart Service", 'https://geo.rijkswaterstaat.nl/arcgis/rest/services/ENC/mcs_inland/MapServer/exts/MaritimeChartService/MapServer'),
    tileArcGisRestLayer("RWS Metrering Vaarwegen", 'https://geo.rijkswaterstaat.nl/arcgis/rest/services/GDR/nwb_metrering/MapServer', 'show:4,5'),
  
    tileWmsLayer('Noordzee Vaarwegmarkeringen Drijvend', 'https://service.pdok.nl/rws/vaarwegmarkeringennld/wms/v1_0?', 'markdrijvendrd'),
    tileWmsLayer('Noordzee Vaarwegmarkeringen Vast', 'https://service.pdok.nl/rws/vaarwegmarkeringennld/wms/v1_0?', 'markvastrd'),
    tileWmsLayer('NWB Vaarwegen - KM markers', 'https://service.pdok.nl/rws/nwbvaarwegen/wms/v1_0?', 'kmmarkeringen', { minZoom: 13.5 }),
    tileWmsLayer('NWB Vaarwegen - Vakken', 'https://service.pdok.nl/rws/nwbvaarwegen/wms/v1_0?', 'vaarwegvakken'),
    tileWmsLayer('VNDS - Bevaarbaarheid', 'https://service.pdok.nl/rws/vnds/wms/v2_0?', 'l_navigability', { styles: 'inspire_common:DEFAULT' }, { styles: 'inspire_common:DEFAULT'}),
    tileWmsLayer('Zwemwaterkwaliteit', 'https://pubgeo.zwemwater.nl/geoserver/zwr_public/wms', 'zwemplekken'),
    tileWmsLayer('Zwemwaterkwaliteit pdok', 'https://service.pdok.nl/provincies/zwemwaterkwaliteit-provinciaal-rijkswateren/wms/v1_0?service=wms&', 'HH.HealthDeterminantMeasure'),
    tileWmsLayer('Zwemwater', 'https://service.pdok.nl/provincies/zwemwater-provinciaal-rijkswateren/wms/v1_0?service=WMS&', 'AM.DesignatedWaters'),
    tileWmsLayer('Vergunde ontgrondingsgebieden', 'https://service.pdok.nl/provincies/begrenzingen-vergunde-ontgrondingsgebieden-zand-grindwinning/wms/v1_0?service=wms&', 'AM.ProspectingAndMiningPermitArea'),
    tileWmsLayer('Grondwaterbeschermingsgebieden', 'https://service.pdok.nl/provincies/grondwaterbeschermingsgebieden/wms/v1_0?service=wms&', 'AM.DrinkingWaterProtectionArea'),

    newGroup('Verkeersscheiddingsstelsel Noordzee', [
      tileWmsLayer('Ankergebieden', 'https://service.pdok.nl/rws/verkeersscheidingsstelsel/wms/v1_0?service=WMS', 'ankergebieden'),
      tileWmsLayer('Begrenzing en Seperatiezones', 'https://service.pdok.nl/rws/verkeersscheidingsstelsel/wms/v1_0?service=WMS', 'begrenzing,seperatiezones'),
      tileWmsLayer('Kilometreringlabels', 'https://service.pdok.nl/rws/verkeersscheidingsstelsel/wms/v1_0?service=WMS', 'kilometreringlabels'),
      tileWmsLayer('Vaargeulen', 'https://service.pdok.nl/rws/verkeersscheidingsstelsel/wms/v1_0?service=WMS', 'vaargeulenpertypeentiteit'),

    ])
  ]),

  newGroup('Nederland Spoor', [
    tileWmsLayer('Kilometrering', 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0?', 'kilometrering'),
    tileWmsLayer('Kruising', 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0?', 'kruising'),
    tileWmsLayer('Overweg', 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0?', 'overweg'),
    tileWmsLayer('Spooras', 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0?', 'spooras'),
    tileWmsLayer('Station', 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0?', 'station'),
    tileWmsLayer('Trace', 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0?', 'trace'),
    tileWmsLayer('Wissel', 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0?', 'wissel'),
  ]),

  newGroup('ProRail', [
    tileArcGisRestLayer('Basiskaart', 'https://maps.prorail.nl/arcgis/rest/services/ProRail_basiskaart/MapServer', 'show:0,1,2,4,5,6,7,8,9'),
    tileArcGisRestLayer('Afscherming', 'https://maps.prorail.nl/arcgis/rest/services/Afscherming/MapServer', 'show:0,1,2,4,5,6,7,8,9'),
    tileArcGisRestLayer('Bedrijfsnoodplan', 'https://maps.prorail.nl/arcgis/rest/services/Bedrijfsnoodplan/MapServer'),
    tileArcGisRestLayer('Energievoorzieningsysteem', 'https://maps.prorail.nl/arcgis/rest/services/Energievoorzieningsysteem/MapServer'),
    tileArcGisRestLayer('Kabelsituatie', 'https://maps.prorail.nl/arcgis/rest/services/Kabelsituatie/MapServer'),
    tileArcGisRestLayer('Treinbeveiligingsysteem ', 'https://maps.prorail.nl/arcgis/rest/services/Treinbeveiligingsysteem/MapServer'),
    tileArcGisRestLayer('Water- en Brandleidingen ', 'https://maps.prorail.nl/arcgis/rest/services/Water__en_Brandleidingen/MapServer'),
  ]),

  newGroup('Nederland Vliegen', [
    // Luchtverkeersleiding Nederland
    // https://geoservices.lvnl.nl/arcgis/rest/services
    tileArcGisRestLayer("LVNL Flight Information Service", lvnlUrl+'VFRchart/FIS/MapServer', 'show:0,1'),
    tileArcGisRestLayer("LVNL Navaids", lvnlUrl+'VFRchart/Navaids/MapServer', 'show:0,1,2,3,4'),
    tileArcGisRestLayer("LVNL Runways", lvnlUrl+'VFRchart/Aerodrome/MapServer', 'show:0'),
    tileArcGisRestLayer("LVNL Airports", lvnlUrl+'VFRchart/Aerodrome/MapServer', 'show:3'),
    tileArcGisRestLayer("LVNL Landingsites", lvnlUrl+'VFRchart/Aerodrome/MapServer', 'show:8'),
    tileArcGisRestLayer("LVNL Routes VFR", lvnlUrl+'VFRchart/Routes/MapServer', 'show:0,1,2'),
    tileArcGisRestLayer("LVNL Routes Military Low Flying", lvnlUrl+'VFRchart/Routes/MapServer', 'show:3'),
    tileArcGisRestLayer("LVNL Obstacles", lvnlUrl+'VFRchart/Obstacles/MapServer', 'show:0,1'),
    tileArcGisRestLayer("LVNL Miscellaneous", lvnlUrl+'VFRchart/Miscellaneous/MapServer', 'show:0,1,2,3,4,5'),
    tileArcGisRestLayer('LVNL Airspaces - Flight Information Region', lvnlUrl+vfrAirspacesUrl, 'show:0'),
    tileArcGisRestLayer('LVNL Airspaces - Low Flying Area', lvnlUrl+vfrAirspacesUrl, 'show:1'),
    tileArcGisRestLayer('LVNL Airspaces - Prohibited Areas', lvnlUrl+vfrAirspacesUrl, 'show:3'),
    tileArcGisRestLayer('LVNL Airspaces - Restricted Areas', lvnlUrl+vfrAirspacesUrl, 'show:4'),
    tileArcGisRestLayer('LVNL Airspaces - Danger Areas', lvnlUrl+vfrAirspacesUrl, 'show:5'),
    tileArcGisRestLayer('LVNL Airspaces - Temporary Reserved Airspace', lvnlUrl+vfrAirspacesUrl, 'show:6'),
    tileArcGisRestLayer('LVNL Airspaces - Temporary Segregated Area', lvnlUrl+vfrAirspacesUrl, 'show:7'),
    tileArcGisRestLayer('LVNL Airspaces - Parajump', lvnlUrl+vfrAirspacesUrl, 'show:8'),
    tileArcGisRestLayer('LVNL Airspaces - Aerodrome Traffic Zones', lvnlUrl+vfrAirspacesUrl, 'show:9'),
    tileArcGisRestLayer('LVNL Airspaces - Transponder Mandatory zone', lvnlUrl+vfrAirspacesUrl, 'show:10'),
    tileArcGisRestLayer('LVNL Airspaces - Airway', lvnlUrl+vfrAirspacesUrl, 'show:11'),
    tileArcGisRestLayer('LVNL Airspaces - Helicopter Protection/Traffic Zone', lvnlUrl+vfrAirspacesUrl, 'show:12'),
    tileArcGisRestLayer('LVNL Airspaces - Radio Mandatory Zone', lvnlUrl+vfrAirspacesUrl, 'show:13'),
    tileArcGisRestLayer('LVNL Airspaces - Glider Areas', lvnlUrl+vfrAirspacesUrl, 'show:14'),
    tileArcGisRestLayer('LVNL Airspaces - Control Area', lvnlUrl+vfrAirspacesUrl, 'show:15'),
    tileArcGisRestLayer('LVNL Airspaces - Terminal Control Area', lvnlUrl+vfrAirspacesUrl, 'show:16'),
    tileArcGisRestLayer('LVNL Airspaces - Control Zone', lvnlUrl+vfrAirspacesUrl, 'show:17'),
  
    tileWmsLayer('Geluidszones rondom vliegvelden', 'https://service.pdok.nl/provincies/geluidzones-rondom-vliegvelden/wms/v1_0?service=wms&', 'AM.NoiseRestrictionZone'),
  ]),

  newGroup('ESRI', [
    esriLayer('Boundaries and Places Alternate','Reference/World_Boundaries_and_Places_Alternate'),
    esriLayer('Boundaries and Places','Reference/World_Boundaries_and_Places'),
    esriLayer('Reference Overlay','Reference/World_Reference_Overlay', { maxZoom: 11 }, { maxZoom: 9 }),
    esriLayer('Transportation','Reference/World_Transportation'),
    esriLayer('Canvas Dark Gray Reference','Canvas/World_Dark_Gray_Reference'),
    esriLayer('Canvas Light Gray Reference','Canvas/World_Light_Gray_Reference'),
    esriLayer('Oceans Reference', 'Ocean/World_Ocean_Reference'),
    // esriLayer('Elevation Hillshade','Elevation/World_Hillshade'),
    // esriLayer('Elevation Hillshade Dark','Elevation/World_Hillshade_Dark'),
  ]),

  newGroup('Stamen/StadiaMaps', [
    stadiaMapsLayer('Stamen Terrain Labels', 'stamen_terrain_labels', { type: '' }),
    stadiaMapsLayer('Stamen Terrain Lines', 'stamen_terrain_lines', { type: '' }),
    stadiaMapsLayer('Stamen Toner Labels', 'stamen_toner_labels', { type: '' }),
    stadiaMapsLayer('Stamen Toner Lines', 'stamen_toner_lines', { type: '' }),
  ]),

  // Needs investigation, 403 forbidden
  // newGroup('TomTom', [
  //   tomtomLayer('Hybrid Main', 'hybrid/main'),
  //   tomtomLayer('Hybrid Night', 'hybrid/night'),
  // ]),

  newGroup('Open Railway Map', [
    // crossOrigin: null - makes it work inside canvas
    // tilePixelRatio: 2 - server returns 512px img for 256 tiles
    xyzLayer("Infrastructure", 'https://{a-c}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png', {}, { opaque: false, crossOrigin: null, tilePixelRatio: 2, maxZoom: 19 }),
    xyzLayer("Max Speeds", 'https://{a-c}.tiles.openrailwaymap.org/maxspeed/{z}/{x}/{y}.png', {}, { opaque: false, crossOrigin: null, tilePixelRatio: 2, maxZoom: 19 }),
    xyzLayer("Signalling", 'https://{a-c}.tiles.openrailwaymap.org/signals/{z}/{x}/{y}.png', {}, { opaque: false, crossOrigin: null, tilePixelRatio: 2, maxZoom: 19 }),
  ]),

  newGroup('NL Fiets- en Wandelnetwerken', [
    tileWmsLayer('Regionale wandelnetwerken', 'https://service.pdok.nl/wandelnet/regionale-wandelnetwerken/wms/v1_0?version=1.3.0&service=wms&', 'wandelnetwerken,wandelknooppunten'),
    tileWmsLayer('Regionale fietsnetwerken', 'https://service.pdok.nl/fietsplatform/regionale-fietsnetwerken/wms/v1_0?version=1.3.0&service=wms&', 'fietsnetwerken,fietsknooppunten'),
    tileWmsLayer('Landelijke fietsroutes', 'https://service.pdok.nl/fietsplatform/landelijke-fietsroutes/wms/v1_0?version=1.3.0&service=wms&', 'landelijke-fietsroutes'),
    tileWmsLayer('Landelijke wandelroutes', 'https://service.pdok.nl/wandelnet/landelijke-wandelroutes/wms/v1_0?version=1.3.0&service=wms&', 'landelijke-wandelroutes'),
    tileWmsLayer('Landelijke wandelroutes ns-wandelingen', 'https://service.pdok.nl/wandelnet/landelijke-wandelroutes/wms/v1_0?version=1.3.0&service=wms&', 'ns-wandelingen'),
    tileWmsLayer('Landelijke wandelroutes ov-stappers', 'https://service.pdok.nl/wandelnet/landelijke-wandelroutes/wms/v1_0?version=1.3.0&service=wms&', 'ov-stappers'),
    tileWmsLayer('Landelijke wandelroutes stad-te-voet', 'https://service.pdok.nl/wandelnet/landelijke-wandelroutes/wms/v1_0?version=1.3.0&service=wms&', 'stad-te-voet'),
    tileWmsLayer('Landelijke wandelroutes streekpaden', 'https://service.pdok.nl/wandelnet/landelijke-wandelroutes/wms/v1_0?version=1.3.0&service=wms&', 'streekpaden'),
  ]),

  newGroup('Waymaked Trails', [
    xyzLayer("Wandelen", 'https://tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png', {}, { opaque: false }),
    xyzLayer("Fietsen", 'https://tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png', {}, { opaque: false }),
    xyzLayer("Mountainbiken", 'https://tile.waymarkedtrails.org/mtb/{z}/{x}/{y}.png', {}, { opaque: false }),
    xyzLayer("Paardrijden", 'https://tile.waymarkedtrails.org/riding/{z}/{x}/{y}.png', {}, { opaque: false }),
    xyzLayer("Wintersportpistes", 'https://tile.waymarkedtrails.org/slopes/{z}/{x}/{y}.png', {}, { opaque: false }),
  ]),

  // newGroup('OpenAIP', [
  //   tileWmsLayer(
  //     'Navaids', 'https://{1-4}.tile.maps.openaip.net/geowebcache/service/wms',
  //     'openaip_approved_navaids',
  //     { type: 'tiles', opacity: 0.7, zIndex: 14, minZoom: 8 },
  //     { params: { SRS: 'EPSG:900913' }}
  //   ),
  //   tileWmsLayer(
  //     'Airports', 'https://{1-4}.tile.maps.openaip.net/geowebcache/service/wms',
  //     'openaip_approved_airports',
  //     { type: 'tiles', opacity: 1, zIndex: 11, minZoom: 6 },
  //     { params: { SRS: 'EPSG:900913' }}
  //   ),
  //   tileWmsLayer(
  //     'Airspaces Geometries', 'https://{1-4}.tile.maps.openaip.net/geowebcache/service/wms',
  //     'openaip_approved_airspaces_geometries',
  //     { type: 'tiles', opacity: 0.5, zIndex: 12 },
  //     { params: { SRS: 'EPSG:900913' }}
  //   ),
  //   tileWmsLayer(
  //     'Hotspots', 'https://{1-4}.tile.maps.openaip.net/geowebcache/service/wms',
  //     'openaip_approved_hotspots',
  //     { type: 'tiles', opacity: 1, zIndex: 15 },
  //     { params: { SRS: 'EPSG:900913' }}
  //   ),
  //   tileWmsLayer(
  //     'Airspaces Labels', 'https://{1-4}.tile.maps.openaip.net/geowebcache/service/wms',
  //     'openaip_approved_airspaces_labels',
  //     { type: 'tiles', opacity: 1, zIndex: 13, minZoom: 9 },
  //     { params: { SRS: 'EPSG:900913' }}
  //   ),
  // ])

  newGroup('risicokaart.nl', [
    // https://rev-portaal.nl/geoserver/wms/rev_public?SERVICE=WMS&VERSION=1.3.0&REQUEST=getcapabilities
    tileWmsLayer('Waterkering', 'https://service.pdok.nl/hwh/keringenimwa/wms/v2_0', 'waterkering'),
    tileWmsLayer('Significant overstromingsrisico gebied', 'https://service.pdok.nl/rws/richtlijnoverstromingsrisico/wms/v2_0', 'ror_risico_v_ab'),
    tileWmsLayer('Significant overstromingsrisico lijnen', 'https://service.pdok.nl/rws/richtlijnoverstromingsrisico/wms/v2_0', 'ror_risico_l'),
    tileWmsLayer('Buisleidingen', 'https://rev-portaal.nl/geoserver/wms/rev_public', 'bl_buisleidingreferentie'),
    tileWmsLayer('Gevaarlijke Stoffen', 'https://rev-portaal.nl/geoserver/wms/rev_public', 'ev_locatie_activiteiten,qs_ev_activiteiten'),
    tileWmsLayer('Evenementlocaties', 'https://servicespub.risicokaart.nl/geoserver/ISOR_RK/wms', 'isor_evenementlocatie'),
    tileWmsLayer('Kwetsbare objecten', 'https://data.rivm.nl/geo/alo/wms', 'isor_kwo_23122022_v2'),
    tileWmsLayer('Aandachtsgebieden', 'https://rev-portaal.nl/geoserver/wms/rev_public', 'rev_public:qs_aandachtsgebieden'),
    tileWmsLayer('Vervoer en buisleidingen', 'https://rev-portaal.nl/geoserver/wms/rev_public', 'rev_public:qs_vervoer_en_buisleidingen'),
    tileWmsLayer('Contouren en veiligheidsafstanden', 'https://rev-portaal.nl/geoserver/wms/rev_public', 'rev_public:qs_prcontouren_en_veiligheidsafstanden'),
    tileWmsLayer('Openbare drinkwaterpunten', 'https://data.rivm.nl/geo/alo/wms', 'rivm_25102023_drinkwaterkranen'),
  ]),

  newGroup('Reddingsbrigades', [
    // kmlLayer('Reddingsbrigades', 'https://www.google.com/maps/d/kml?forcekml=1&mid=1cgVKpFroxS611E029v3R8NgB5tAQ2X5Q&lid=AvGdMI22drc'), // not working because Google doesn't send coords
    kmlLayer('Reddingsposten', 'https://www.google.com/maps/d/kml?forcekml=1&mid=1cgVKpFroxS611E029v3R8NgB5tAQ2X5Q&lid=z68dXM7JL5mY.kefqF5EC8xf0'),
    kmlLayer('Nationale Reddingsvloot', 'https://www.google.com/maps/d/kml?forcekml=1&mid=1cgVKpFroxS611E029v3R8NgB5tAQ2X5Q&lid=zED5Vfvxvsyw.kH55-jKNc-gY')
  ]),

  newGroup('Individuals', [
    tileWmsLayer('Drone no-fly zones', 'https://service.pdok.nl/lvnl/drone-no-flyzones/wms/v1_0?', 'Luchtvaartgebieden'),
    tileWmsLayer('Drone no-fly zones zonder natura2000', 'https://service.pdok.nl/lvnl/drone-no-flyzones/wms/v1_0?', 'luchtvaartgebieden_zonder_natura2000'),
    tileWmsLayer('Drone no-fly zones landingsite', 'https://service.pdok.nl/lvnl/drone-no-flyzones/wms/v1_0?', 'landingsite'),
    tileWmsLayer(
      'Deutscher Wetterdienst', 'https://maps.dwd.de/geoserver/wms?', "dwd:RX-Produkt",
      { opacity: 0.3, type: 'overlay'},
      { params: { validtime: new Date().getTime() }, projection: "EPSG:3857", attributions: "Deutscher Wetterdienst (DWD)" }
    ),
    osmLayer("Open Sea Map", 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', {}, { opaque: false }),
    // osmLayer("(noSSL) NYPL - classic world", 'http://maps.nypl.org/warper/maps/tile/12602/{z}/{x}/{y}.png', {}, { opaque: false }),
    xyzLayer('ADSB OpenAIP', 'https://map.adsbexchange.com/mapproxy/tiles/1.0.0/openaip/ul_grid/{z}/{x}/{y}.png',
      { type: 'overlay', opacity: 0.7 }
    ),
    xyzLayer('MarineTraffic', 'https://tiles.marinetraffic.com/ais_helpers/shiptilesingle.aspx?output=png&sat=1&grouping=shiptype&tile_size=256&legends=1&zoom={z}&X={x}&Y={y}',
        {},
        { maxZoom: 18 }
    ),
    tileWmsLayer('BRP Gewasperselen', 'https://service.pdok.nl/rvo/brpgewaspercelen/wms/v1_0', 'BrpGewas', { minZoom: 13.5 }),
    epsg28992WmtsLayer('PDOK labels', 'lufolabels', 'https://service.pdok.nl/bzk/luchtfotolabels/wmts/v1_0?', { type: '', maxZoom: 19.5 }),
    mapyLayer('Mapy Labels and borders', 'names-overlay', {}, { maxZoom: 20 }),
  ])
]);

/*
 ███████████                     █████                                  █████                                                      
░░███░░░░░░█                    ░░███                                  ░░███                                                       
 ░███   █ ░   ██████   ██████   ███████   █████ ████ ████████   ██████  ░███         ██████   █████ ████  ██████  ████████   █████ 
 ░███████    ███░░███ ░░░░░███ ░░░███░   ░░███ ░███ ░░███░░███ ███░░███ ░███        ░░░░░███ ░░███ ░███  ███░░███░░███░░███ ███░░  
 ░███░░░█   ░███████   ███████   ░███     ░███ ░███  ░███ ░░░ ░███████  ░███         ███████  ░███ ░███ ░███████  ░███ ░░░ ░░█████ 
 ░███  ░    ░███░░░   ███░░███   ░███ ███ ░███ ░███  ░███     ░███░░░   ░███      █ ███░░███  ░███ ░███ ░███░░░   ░███      ░░░░███
 █████      ░░██████ ░░████████  ░░█████  ░░████████ █████    ░░██████  ███████████░░████████ ░░███████ ░░██████  █████     ██████ 
░░░░░        ░░░░░░   ░░░░░░░░    ░░░░░    ░░░░░░░░ ░░░░░      ░░░░░░  ░░░░░░░░░░░  ░░░░░░░░   ░░░░░███  ░░░░░░  ░░░░░     ░░░░░░  
                                                                                               ███ ░███                            
                                                                                              ░░██████                             
                                                                                               ░░░░░░                              
*/
const featureLayers = newGroup('FeatureLayers', [
  newGroup('Rijkswaterstaat', [
    esriFeatureLayer(
      'Strandpalen',
      'https://geo.rijkswaterstaat.nl/arcgis/rest/services/GDR/topografie_kust/FeatureServer/',
      '5'
    ),
    esriFeatureLayer(
      'Vuurtorens',
      'https://geo.rijkswaterstaat.nl/arcgis/rest/services/GDR/topografie_kust/FeatureServer/',
      '2',
      {
        style: new Style({
          image: new Circle({
            fill: new Fill({
              color: 'rgba(255, 0, 0, 255)',
            }),
            radius: 7,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 255)',
              width: 1,
            }),
          })
        })
      }
    ),
    esriFeatureLayer(
        'NAP peilpunten',
        'https://geo.rijkswaterstaat.nl/arcgis/rest/services/GDR/nap/FeatureServer/',
        '0'
    ),
  ]),

  newGroup('Grenzen Nederland', [
    wfsLayer("Gemeentegrenzen Nederland", 'https://service.pdok.nl/kadaster/bestuurlijkegebieden/wfs/v1_0?service=WFS&', 'bestuurlijkegebieden:Gemeentegebied'),
    wfsLayer("Provinciegrenzen Nederland", 'https://service.pdok.nl/kadaster/bestuurlijkegebieden/wfs/v1_0?service=WFS&', 'bestuurlijkegebieden:Provinciegebied', {
      style: new Style({
        fill: new Fill({
          color: 'rgba(0, 255, 0, 0.1)',
        }),
        stroke: new Stroke({
          color: 'rgba(0, 255, 0, 1)',
          width: 2,
        })
      }),
    }),
  
    wfsLayer("Veiligheidsregio Nederland", 'https://service.pdok.nl/cbs/gebiedsindelingen/2024/wfs/v1_0?service=WFS&', 'gebiedsindelingen:veiligheidsregio_gegeneraliseerd', {
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 165, 0, 0.1)',
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 1)',
          width: 2,
        })
      }),
    }),
    wfsLayer("GGD Regio Nederland", 'https://service.pdok.nl/cbs/gebiedsindelingen/2024/wfs/v1_0?service=WFS&', 'gebiedsindelingen:ggdregio_gegeneraliseerd'),
    wfsLayer("Wijken Nederland", 'https://service.pdok.nl/cbs/gebiedsindelingen/2024/wfs/v1_0?service=WFS&', 'gebiedsindelingen:wijk_gegeneraliseerd'),
    wfsLayer("Buurten Nederland", 'https://service.pdok.nl/cbs/gebiedsindelingen/2024/wfs/v1_0?service=WFS&', 'gebiedsindelingen:buurt_gegeneraliseerd'),
    wfsLayer("Kadaster Percelen", 'https://service.pdok.nl/kadaster/kadastralekaart/wfs/v5_0?SERVICE=WFS&', 'kadastralekaart:Perceel'),
    wfsLayer("Kadaster Bebouwing", 'https://service.pdok.nl/kadaster/kadastralekaart/wfs/v5_0?SERVICE=WFS&', 'kadastralekaart:Bebouwing', {
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 165, 0, 0.1)',
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 1)',
          width: 2,
        })
      }),
    }),
  ]),

  newGroup('Postcodes', [
    esriFeatureLayer(
      'Postcodes cijfer',
      'https://services.arcgis.com/nSZVuSZjHpEZZbRo/arcgis/rest/services/Postcodevlakken_PC4/FeatureServer/',
      0,
      {
        style: new Style({
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.1)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 255)',
            width: 1,
          }),
        })
      }
    ),
    esriFeatureLayer(
        'Postcodes compleet',
        'https://services.arcgis.com/nSZVuSZjHpEZZbRo/arcgis/rest/services/Postcodevlakken_PC6/FeatureServer/',
        0,
        {
          style: new Style({
            fill: new Fill({
              color: 'rgba(0, 0, 0, 0.1)',
            }),
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 255)',
              width: 1,
            }),
          })
        }
    )
  ]),

  newGroup('Elektriciteitsnet', [
    // Deprecated: uit productie per 11 juni 2025
    // https://www.pdok.nl/-/dataset-beschikbare-capaciteit-elektriciteitsnet-van-kadaster-uit-productie
    tileWmsLayer('Locatie stations', 'https://service.pdok.nl/kadaster/netcapaciteit/wms/v1_0?service=WMS', 'Stations'),

    newGroup('Liander',[
      tileWmsLayer('Hoogspanningskabels', 'https://service.pdok.nl/liander/elektriciteitsnetten/wms/v1_0?service=WMS', 'Hoogspanningskabels'),
      tileWmsLayer('Middenspanningskabels', 'https://service.pdok.nl/liander/elektriciteitsnetten/wms/v1_0?service=WMS', 'Middenspanningskabels', { minZoom: 13.5 }),
      tileWmsLayer('Laagspanningskabels', 'https://service.pdok.nl/liander/elektriciteitsnetten/wms/v1_0?service=WMS', 'Laagspanningskabels', { minZoom: 14.5 }),
      tileWmsLayer('Onderstations', 'https://service.pdok.nl/liander/elektriciteitsnetten/wms/v1_0?service=WMS', 'Onderstations', { minZoom: 13.5 }),
      tileWmsLayer('Middenspanningsinstallaties', 'https://service.pdok.nl/liander/elektriciteitsnetten/wms/v1_0?service=WMS', 'Middenspanningsinstallaties', { minZoom: 15.5 }),
      tileWmsLayer('Laagspanningsverdeelkasten', 'https://service.pdok.nl/liander/elektriciteitsnetten/wms/v1_0?service=WMS', 'Laagspanningsverdeelkasten', { minZoom: 15.5 }),
  
    ]),
  
    newGroup('Stedin', [
      esriFeatureLayer(
        'Laagspanningsverbindingen',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '2',
        {
          minZoom: 14.9,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(0, 0, 255, 255)',
              width: 1,
            })
          })
        }
      ),
      esriFeatureLayer(
        'Middenspanningsverbindingen',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '3',
        {
          minZoom: 13.9,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(197, 0, 255, 255)',
              width: 1,
            })
          })
        }
      ),
      esriFeatureLayer(
        'Hoogspanningsverbindingen',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '4',
        { 
          minZoom: 12
        }
      ),
      esriFeatureLayer(
        'Laagspanningsstations',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '5',
        {
          minZoom: 14,
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 255, 0, 255)',
            }),
            stroke: new Stroke({
              color: 'rgba(110, 110, 110, 255)',
              width: 1,
            })
          })
        }
      ),
      esriFeatureLayer(
        'MiddenLaagspanningsstations',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '6',
        {
          minZoom: 14,
          style: new Style({
            fill: new Fill({
              color: 'rgba(0, 255, 255, 255)',
            }),
            stroke: new Stroke({
              color: 'rgba(110, 110, 110, 255)',
              width: 1,
            })
          })
        }
      ),
      esriFeatureLayer(
        'Middenspanningsstations',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '7',
        {
          minZoom: 14,
          style: new Style({
            fill: new Fill({
              color: 'rgba(0, 168, 132, 255)',
            }),
            stroke: new Stroke({
              color: 'rgba(110, 110, 110, 255)',
              width: 1,
            })
          })
        }
      ),
      esriFeatureLayer(
        'Hoogspanningsstations',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '8',
        {
          minZoom: 13,
          style: new Style({
            fill: new Fill({
              color: 'rgba(187, 227, 252, 255)',
            }),
            stroke: new Stroke({
              color: 'rgba(110, 110, 110, 255)',
              width: 1,
            })
          })
        }
      ),
      esriFeatureLayer(
        'Gas',
        'https://services3.arcgis.com/PeOi6aWiQVQNdaI8/arcgis/rest/services/KM_Gasvervangingsdata/FeatureServer/',
        '1',
        {
          minZoom: 14,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(0,197,255,255)',
              width: 1,
            })
          })
        }
      ),
    ]),
  ]),


  newGroup('Aardbevingen', [
    geoJSONLayer("Earthquakes Significant Month", 'https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/significant_month.geojson', {
      style: new Style({
        image: new Circle({
          fill: new Fill({
            color: 'rgba(255, 0, 0, 255)',
          }),
          radius: 7,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 255)',
            width: 1,
          }),
        })
      })
    }),
    geoJSONLayer("Earthquakes all Month", 'https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/all_month.geojson', {
      style: new Style({
        image: new Circle({
          fill: new Fill({
            color: 'rgba(255, 0, 0, 255)',
          }),
          radius: 7,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 255)',
            width: 1,
          }),
        })
      })
    })
  ]),
]);


/*
██       ██████   ██████  ██  ██████
██      ██    ██ ██       ██ ██
██      ██    ██ ██   ███ ██ ██
██      ██    ██ ██    ██ ██ ██
███████  ██████   ██████  ██  ██████
*/

// set zoom, center and rotation based on URL
if (window.location.hash !== '') {
  // try to restore center, zoom-level and rotation from the URL
  var hash = window.location.hash.replace('#map=', '');
  var parts = hash.split('/');
  if (parts.length === 5) {
    zoom = parseInt(parts[0], 10);
    center = [parseFloat(parts[1]), parseFloat(parts[2])];
    rotation = parseFloat(parts[3]);
    visibleLayers = getVisibleLayersFromURL(parts[4]);
  }
}

// initiate map:
const map = new Map({
  controls: defaultControls({attribution: false}).extend([
    new FullScreen(),
    new Attribution({
      collapsible: true
    })
  ]),
  interactions: defaultInteractions().extend([new DragRotate()]),
  target: 'map',
  view: new View({
    center: center,
    zoom: zoom,
    rotation: rotation
  }),
});
map.addLayer(baseMaps);
map.addLayer(overLayers);
map.addLayer(featureLayers);
map.addOverlay(overlayLayer);

console.log(visibleLayers)

// set layervisibility
setLayerVisibility(visibleLayers)

// update URL logic
var shouldUpdate = true;
var view = map.getView();

var getVisibleLayers = function (clean = false) {
  var visibleLayers = []

  map.getAllLayers().forEach(element => {
    if(element.getVisible()) {
      visibleLayers.push(element.get('title'))
    }
  });
  if (clean) {
    return visibleLayers
  } else {
    return btoa(JSON.stringify(visibleLayers)).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
  }
}

var updatePermalink = function () {
  if (!shouldUpdate) {
    // do not update the URL when the view was changed in the 'popstate' handler
    shouldUpdate = true;
    return;
  }

  var center = view.getCenter();
  var hash =
    '#map=' +
    view.getZoom() +
    '/' +
    Math.round(center[0] * 100) / 100 +
    '/' +
    Math.round(center[1] * 100) / 100 +
    '/' +
    view.getRotation() +
    '/' + getVisibleLayers();
  var state = {
    zoom: view.getZoom(),
    center: view.getCenter(),
    rotation: view.getRotation(),
    layers: getVisibleLayers(true),
  };
  window.history.pushState(state, 'map', hash);
};

map.on('moveend', updatePermalink);

// restore the view state when navigating through the history, see
// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
window.addEventListener('popstate', function (event) {
  if (event.state === null) {
    return;
  }
  map.getView().setCenter(event.state.center);
  map.getView().setZoom(event.state.zoom);
  map.getView().setRotation(event.state.rotation);
  setLayerVisibility(event.state.layers);
  shouldUpdate = false;
});

///////////////////////////////////////////////////////////////////////////
/////////////////////////      LAYERSWITCHER      /////////////////////////
///////////////////////////////////////////////////////////////////////////
const layerSwitcher = new LayerSwitcher({
  reverse: false,
  groupSelectStyle: 'none', // Can be 'children' [default], 'group' or 'none'
  tipLabel: 'Légende', // Optional label for button
  //activationMode: 'click'
});
map.addControl(layerSwitcher);

///////////////////////////////////////////////////////////////////////////
/////////////////////////      MOUSEPOSITION      /////////////////////////
///////////////////////////////////////////////////////////////////////////
var mousePositionControl = new MousePosition({
  coordinateFormat: function(coord) {
    return toStringHDMS(coord, 1);
  },
  //ol.coordinate.createStringXY(4),
  projection: 'EPSG:4326',
  placeholder: '##° ##′ ##.#″ # ##° ##′ ##.#″ #',
});
map.addControl(mousePositionControl);

///////////////////////////////////////////////////////////////////////////
/////////////////////////        SCALELINE        /////////////////////////
///////////////////////////////////////////////////////////////////////////
map.addControl(new ScaleLine());

///////////////////////////////////////////////////////////////////////////
/////////////////////////       GEOLOCATION       /////////////////////////
///////////////////////////////////////////////////////////////////////////

const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: map.getView().getProjection(),
});

// ask for current location
geolocation.setTracking(true);

// handle geolocation error
geolocation.on('error', function (error) {
  console.log(error)
});

const accuracyFeature = new Feature();
geolocation.on('change:accuracyGeometry', function () {
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});

const positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  })
);

geolocation.on('change:position', function () {
  const coordinates = geolocation.getPosition();
  positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
});

///////////////////////////////////////////////////////////////////////////
/////////////////////////       DebugLayers       /////////////////////////
///////////////////////////////////////////////////////////////////////////

const specialDebugLayers = newGroup('Special/Debug', [
  new Graticule({
    title: "Grid",
    showLabels: true,
    wrapX: false,
    visible: false
  }),
  new TileLayer({
    title: 'Tile Debug',
    source: new TileDebug(),
    visible: false
  }),
  new VectorLayer({
    title: 'GPS Position',
    source: new VectorSource({
      features: [accuracyFeature, positionFeature],
    }),
    visible: true
  }),
]);
map.addLayer(specialDebugLayers);

///////////////////////////////////////////////////////////////////////////
/////////////////////////       LoadSpinner       /////////////////////////
///////////////////////////////////////////////////////////////////////////

map.on('loadstart', function () {
  map.getTargetElement().classList.add('spinner');
});
map.on('loadend', function () {
  map.getTargetElement().classList.remove('spinner');
});


/*
██████   ██████  ██████        ██    ██ ██████  ███████
██   ██ ██    ██ ██   ██       ██    ██ ██   ██ ██
██████  ██    ██ ██████  █████ ██    ██ ██████  ███████
██      ██    ██ ██            ██    ██ ██           ██
██       ██████  ██             ██████  ██      ███████
*/
map.on('click', function(e){
  overlayLayer.setPosition(undefined);
  while (overlayContainerElement.firstChild) {
    overlayContainerElement.firstChild.remove();
  }
  featureLayerPopUp(e, 'Strandpalen', ['GEBIEDNA_4', 'GEBIEDNU_3']);
  featureLayerPopUp(e, 'Vuurtorens', ['NR', 'NAAM']);
  featureLayerPopUp(e, 'NAP peilpunten', ['omschrijving', 'hoogte']);
  featureLayerPopUp(e, 'Earthquakes Significant Month', ['mag', 'place']);
  featureLayerPopUp(e, 'Earthquakes all Month', ['mag', 'place']);
  featureLayerPopUp(e, 'Gemeentegrenzen Nederland', ['naam']);
  featureLayerPopUp(e, 'Provinciegrenzen Nederland', ['naam']);
  featureLayerPopUp(e, 'Veiligheidsregio Nederland', ['statcode', 'statnaam']);
  featureLayerPopUp(e, 'GGD Regio Nederland', ['statnaam']);
  featureLayerPopUp(e, 'Wijken Nederland', ['statnaam']);
  featureLayerPopUp(e, 'Buurten Nederland', ['statnaam']);
  featureLayerPopUp(e, 'Kadaster Percelen', ['perceelnummer']);
  featureLayerPopUp(e, 'Kadaster Bebouwing', ['identificatieBAGPND']);
  featureLayerPopUp(e, 'Postcodes cijfer', ['PC4']);
  featureLayerPopUp(e, 'Postcodes compleet', ['PC6']);
})